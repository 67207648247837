@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0');
*{padding: 0px; margin: 0px;}

html {
    overflow-x: hidden;
}

body {
    font-family: "Poppins", sans-serif;
    overflow-x: hidden;

    @media (max-width: 767px) {
       padding-top: 150px;
    }
}

.lab {
    &-container {
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
    }
    
    &-flex {
        display: flex;
    }

    &-align-items {
        &-center {
            align-items: center;
        }
        &-end {
            margin-left: auto;
        }
    }

    &-justify-content {
        &-center {
            justify-content: center;
        }

        &-between {
            justify-content: space-between;
        }
    }
}

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


